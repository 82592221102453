import React from 'react';
import {Button} from 'react-bootstrap';

export default function Belt(){
  return(
    <>
    <div className="container-fluid" id="belt">
      <div className="row">
        <div className="col-12">
          <h5><span className="bold">What's Your Home Worth?</span> Find your home or condo's value in today's market, for free. <a href=""><Button variant="success">FIND NOW</Button></a></h5>
        </div>
      </div>
    </div>
    </>
  )
}

import React from 'react';
import './App.css';
import Header from './components/header';
import banner from './images/banner.png';
import Belt from './components/belt';
import Bottom from './components/bottom';
import list from './images/listing.png';

function App() {
  return (
    <>
    <Header />
    <a href="https://www.zanderteam.com/property-search/search-form/"><img src={banner} alt="The Zander Team Utah Home Search" id="banner" /></a>
    <Belt />
    <a href="https://www.zanderteam.com/property-search/results/?searchid=453425"><img src={list} alt="The Zander Team Listing Searches" /></a>
    <Bottom />
    </>
  );
}

export default App;

import React from 'react';

export default function Bottom(){
  return(
    <>
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-3">
            <ul>
              <li className="listhead">CONTACT</li>
              <li>Zander Real Estate Team</li>
              <li>4656 W Daybreak Rim Way</li>
              <li>South Jordan, UT</li>
              <li>O: <a href="tel:8014462662">(801) 446-2662</a></li>
              <li>E: <a href="https://www.zanderteam.com/contact/">Email Us</a></li>
            </ul>
          </div>
          <div className="col-3">
            <ul>
              <li className="listhead">CONNECT</li>
              <li><a href="https://www.facebook.com/ZanderTeam">Facebook</a></li>
              <li><a href="https://twitter.com/zanderteam">Twitter</a></li>
              <li><a href="https://www.instagram.com/zanderrealestateteam/">Instagram</a></li>
              <li><a href="https://www.youtube.com/channel/UCw2uCc5qsW3OyvFrk0adfmw">YouTube</a></li>
            </ul>
          </div>
          <div className="col-3">
            <ul>
              <li className="listhead">NAVIGATION</li>
              <li><a href="https://www.zanderteam.com/property-search/search-form/">Search</a></li>
              <li><a href="https://www.zanderteam.com/communities/">Communities</a></li>
              <li><a href="https://www.zanderteam.com/buyers/">Buyers</a></li>
              <li><a href="https://www.zanderteam.com/sellers/">Sellers</a></li>
              <li><a href="https://www.zanderteam.com/about/">About</a></li>
              <li><a href="https://www.zanderteam.com/contact/">Contact</a></li>
            </ul>
          </div>
          <div className="col-3">
            <ul>
              <li className="listhead">POPULAR SEARCHES</li>
              <li><a href="https://www.zanderteam.com/property-search/results/?searchid=453425">Featured Listings</a></li>
              <li><a href="https://www.zanderteam.com/property-search/results/?searchid=453428">Newest Listings</a></li>
              <li><a href="https://www.zanderteam.com/property-search/results/?searchid=453430">Single Family</a></li>
              <li><a href="https://www.zanderteam.com/property-search/results/?searchid=453432">Condos & Townhomes</a></li>
              <li><a href="https://www.zanderteam.com/property-search/results/?searchid=453433">Luxury Homes</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    </>
  )
}

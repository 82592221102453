import React from 'react';
import {Nav, Navbar, NavDropdown, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import logo from '../images/logo.png';

export default function Header(){
  return(
    <>
    <div className="wrapper">
      <div className="container" id="topbar">
        <div className="row">
          <div className="col-12">
            <Navbar bg="dark" expand="lg">
              <Navbar.Brand href="/"><img src={logo} alt="The Zander Team Real Estate" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="topnav" />
              <Navbar.Collapse id="topnav">
                <Nav className="mr-auto">
                  <Nav.Link href="https://www.zanderteam.com/property-search/search-form/">Search</Nav.Link>
                  <Nav.Link href="https://www.zanderteam.com/communities/">Communities</Nav.Link>
                  <Nav.Link href="https://www.zanderteam.com/buyers/">Buyers</Nav.Link>
                  <Nav.Link href="https://www.zanderteam.com/sellers/">Sellers</Nav.Link>
                  <Nav.Link href="https://www.zanderteam.com/about/">About</Nav.Link>
                  <Nav.Link href="https://www.zanderteam.com/contact/">Contact</Nav.Link>
                  <NavDropdown title="Articles" id="artdrop">
                    <NavDropdown.Item><Link to="/whats-my-home-worth-daybreak">What's My Home Worth in Daybreak UT?</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/listing-agent-daybreak">Listing Agent Daybreak UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/sell-my-home-daybreak">Sell My Home Daybreak UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/whats-my-home-worth-south-jordan">What's My Home Worth in South Jordan UT?</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/sell-my-home-south-jordan">Sell My Home South Jordan UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/top-realtor-daybreak">Top Realtor in Daybreak UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/new-homes-for-sale-daybreak">New Homes for Sale in Daybreak UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/55-plus-communities-daybreak">55 Plus Communities in Daybreak UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/real-estate-agent-daybreak">Real Estate Agent in Daybreak UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/new-homes-for-sale-south-jordan">New Homes for Sale South Jordan UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/homes-for-sale-daybreak">Homes for Sale in Daybreak UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/real-estate-agent-south-jordan">Real Estate Agent in South Jordan UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/sell-my-home-salt-lake-city">Sell My Home in Salt Lake City</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/top-real-estate-agent-salt-lake-city">Top Real Estate Agent in Salt Lake City</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/listing-agent-west-jordan">Listing Agent in West Jordan UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/whats-my-home-worth-salt-lake-city">What's My Home Worth in Salt Lake City?</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/listing-agent-taylorsville">Listing Agent in Taylorsville UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/springhouse-village-homes-daybreak">SpingHouse Village Homes in Daybreak UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/listing-agent-west-jordan">Listing Agent in West Jordan UT</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/whats-my-home-worth-west-jordan">What's My Home Worth in West Jordan UT?</Link></NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

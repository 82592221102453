import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import App from './App';
import Anchor from './components/anchor';
import {Backpage1, Backpage2, Backpage3, Backpage4, Backpage5, Backpage6, Backpage7, Backpage8, Backpage9, Backpage10, Backpage11, Backpage12, Backpage13, Backpage14, Backpage15, Backpage16, Backpage17, Backpage18, Backpage19, Backpage20} from './components/backpages';

export default function Routes(){
  return(
    <Router>
      <Anchor />
      <Switch>
        <Route exact path="/whats-my-home-worth-daybreak">
          <Backpage1 />
        </Route>
        <Route exact path="/listing-agent-daybreak">
          <Backpage2 />
        </Route>
        <Route exact path="/sell-my-home-daybreak">
          <Backpage3 />
        </Route>
        <Route exact path="/whats-my-home-worth-south-jordan">
          <Backpage4 />
        </Route>
        <Route exact path="/sell-my-home-south-jordan">
          <Backpage5 />
        </Route>
        <Route exact path="/top-realtor-daybreak">
          <Backpage6 />
        </Route>
        <Route exact path="/new-homes-for-sale-daybreak">
          <Backpage7 />
        </Route>
        <Route exact path="/55-plus-communities-daybreak">
          <Backpage8 />
        </Route>
        <Route exact path="/real-estate-agent-daybreak">
          <Backpage9 />
        </Route>
        <Route exact path="/new-homes-for-sale-south-jordan">
          <Backpage10 />
        </Route>
        <Route exact path="/homes-for-sale-daybreak">
          <Backpage11 />
        </Route>
        <Route exact path="/real-estate-agent-south-jordan">
          <Backpage12 />
        </Route>
        <Route exact path="/sell-my-home-salt-lake-city">
          <Backpage13 />
        </Route>
        <Route exact path="/top-real-estate-agent-salt-lake-city">
          <Backpage14 />
        </Route>
        <Route exact path="/listing-agent-west-jordan">
          <Backpage15 />
        </Route>
        <Route exact path="/whats-my-home-worth-salt-lake-city">
          <Backpage16 />
        </Route>
        <Route exact path="/listing-agent-west-valley">
          <Backpage17 />
        </Route>
        <Route exact path="/whats-my-home-worth-west-jordan">
          <Backpage18 />
        </Route>
        <Route exact path="/listing-agent-taylorsville">
          <Backpage19 />
        </Route>
        <Route exact path="/springhouse-village-homes-daybreak">
          <Backpage20 />
        </Route>
        <Route exact path="/">
          <App />
        </Route>
      </Switch>
    </Router>
  )
}
